import React, { useState, useMemo } from 'react'

import { FormSelect } from 'react-bootstrap'
import countryList from 'react-select-country-list'

function CountrySelector({setCountry}) {
  const [value, setValue] = useState('US')
  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = value => {
    setCountry(value);
    setValue(value);
  }

  return (
    
    <FormSelect value={value} onChange={changeHandler} >
        {options &&
        options.map((option) => (
             
            <option key={option.value} value={option.value}>{option.label}</option>
            
        ))}
    </FormSelect>
  
  )

}

export default CountrySelector