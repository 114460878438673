import Card from 'react-bootstrap/Card';
import { Row, Col, Container} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../config';

const CompanyInfo = () => {
    
    
    const colBorderStyle = {
        border: '1px solid rgba(0, 0, 0, 0.175)',
    };

    return (
        <Container id='contact-us'>
        <Card className='mt-5 p-3'>
            <Card.Body>
                <Row className='p-5'>
                    <Col style={colBorderStyle} xs="12">
                        <Row className='m-3 p-4' >
                            <Col xs="12" sm="12">
                                <center><FontAwesomeIcon icon={faMapMarkedAlt} /></center>
                            </Col>
                            <Col xs="12" sm="12">
                                <center>Corp Address</center>
                            </Col>
                            <Col xs="12" sm="12">
                                <center>{global.config.site.company.name}</center>
                            </Col>
                            <Col xs="12" sm="12">
                                <center>{global.config.site.company.address}</center>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" sm="12">
                        <Row className='mt-5 g-0' >
                            <Col className='pb-5' xs="12" lg="4">
                                <Row className='p-4 me-lg-3' style={colBorderStyle} >
                                    <Col xs="12" sm="12">
                                        <center><FontAwesomeIcon icon={faMapMarkedAlt} /></center>
                                    </Col>
                                    <Col xs="12" sm="12">
                                        <center>Return Address</center>
                                    </Col>
                                    <Col xs="12" sm="12">
                                        <center>{global.config.site.company.return_address}</center>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='pb-5' xs="12" lg="4">
                                <Row style={colBorderStyle} className='p-4 me-lg-3' >
                                    <Col xs="12" sm="12">
                                        <center><FontAwesomeIcon icon={faMobileAlt} /></center>
                                    </Col>
                                    <Col xs="12" sm="12">
                                        <center>Customer Support (24/7)</center>
                                    </Col>
                                    <Col xs="12" sm="12">
                                        <center>{global.config.site.company.customer_support_number}</center>
                                    </Col>
                                    <Col xs="12" sm="12">&nbsp;</Col>
                                </Row>
                            </Col>
                            <Col className='pb-5' xs="12" lg="4">
                                <Row style={colBorderStyle} className='p-4'>
                                    <Col xs="12" sm="12">
                                        <center><FontAwesomeIcon icon={faEnvelope} /></center>
                                    </Col>
                                    <Col xs="12" sm="12">
                                        <center>Email Us</center>
                                    </Col>
                                    <Col xs="12" sm="12">
                                        <center>{global.config.site.company.email}</center>
                                    </Col>
                                    <Col xs="12" sm="12">&nbsp;</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
            </Card.Body>
        </Card>
        </Container>
    );
};

export default CompanyInfo;