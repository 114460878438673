import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from 'react-bootstrap';

import Image from 'react-bootstrap/Image';

import '../config';

const RelatedProducts = () => {
    var index = 0;

    const pStyle = {
        fontSize: '20px',
    };

    const titleStyle = {
        fontSize: '26px',
        color: '#000',
        textDecoration: 'none',
    };

    const priceStyle = {
        fontSize: '20px',
        textDecoration: 'none',
    };

    const colStyle = {
        border: '1px solid rgba(0, 0, 0, 0.175)',
    };

    function GetImage(props){
        
            return <Col className='p-3' xs="12" md="6" lg="3" xl="3" key={props.product.id}>
                        <div style={colStyle} className='p-3'>
                    <Link style={{textDecoration:'none'}} to={`/posts/${props.product.id}`}>
                        <Image alt={props.product.title} width="100%" src={props.product.logo} />
                        <center><p style={titleStyle}>{props.product.title}</p>
                        <p style={priceStyle}>{props.product.price}</p>
                        </center>
                    </Link>
            </div>
        </Col>
        
    }

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(`${global.config.api.products_url}?limit=4&site=${global.config.api.site_id}`);
            const postsResp = await resp.json();
            setPosts(postsResp);
        };

        getPosts();
    }, []);


    return (
        <Container className='mt-3'>
            <center><h1>RELATED PRODUCTS</h1></center>
            <Row>
                
                {posts.map(post => (
                    
                    <GetImage product={post} key={post.id} />
                ))}

                
            </Row>
            
        </Container>
    );
};

export default RelatedProducts;