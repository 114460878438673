import React from "react";
import Image from 'react-bootstrap/Image';
import { Row, Col } from 'react-bootstrap';

const preventNegativeQty = val => (val < 1 ? 1 : val);
const CartItem = ({ item, onRemoveItem, col, onUpdateQtyItem }) => {

  const imgStyle = {
    height: '30px',
    margin: 'auto',
    
  };

  const numStyle = {
    width: '50%',
  };
  const numTdStyle = {
    width: '20%',
  };
  const divStyle = {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  };

  const handleChange = (id, e) => {
  
    onUpdateQtyItem(id, e.target.value);
      
  };

  return (
    <>
    {col && 
      <>
        <Col className="pb-2" xs="12">
          <button className="btn btn-danger" type="button" onClick={() => onRemoveItem(item.id)}>
              x
          </button>
        </Col>
        <Col xs="12" className="pb-2 me-auto">
          <Row>
            <Col>
            <span><b>PRODUCT:</b></span>
            </Col>
            <Col>
            <span>{item.title}</span>
            </Col>
          </Row>
           
        </Col>
        <Col className="pb-2" xs="12">
        <Row>
            <Col>
            <span><b>PRICE:</b></span>
            </Col>
            <Col>
            <span>${item.price}</span>
            </Col>
          </Row>
        </Col>
        <Col className="pb-2" xs="12">
        <Row>
            <Col>
            <span><b>QUANTITY:</b></span>
            </Col>
            <Col>
            <span><input style={numStyle} className="form-control" type="number" id="quantity" onChange={ e => handleChange(item.id, e)} value={item.qty} name="quantity" min="1" /></span>
            </Col>
          </Row>
        </Col>
        <Col className="pb-2" xs="12">
        <Row>
            <Col>
            <span><b>SUBTOTAL:</b></span>
            </Col>
            <Col>
            <span>${item.sub_total.toFixed(2)}</span>
            </Col>
          </Row>
        </Col>
      </>
    }

    {col === undefined &&
    
      <tr>
        <td>
          <button className="btn btn-danger" type="button" onClick={() => onRemoveItem(item.id)}>
            x
          </button>
        </td>
        <td>
          <div style={divStyle}>
          <Image src={item.logo} style={imgStyle}></Image>
          </div>
        </td>
        <td>
          {item.title}
        </td>
        <td>
          ${item.price}

        </td>
        <td style={numTdStyle}>
          <input style={numStyle} className="form-control" type="number" id="quantity" onChange={ e => handleChange(item.id, e)} value={item.qty} name="quantity" min="1" />
          
        </td>
        <td>
          ${item.sub_total.toFixed(2)}
        </td>
      </tr>
  }
    </>
  );
};

export default CartItem;