
import '../config';
import Header from './header';
import Discover from './discover';
import BestSeller from './best_sellers';
import OrderShipping from './order_shipping';
import OurProducts from './our_products';
import CompanyInfo from './company_info';
import Footer from './footer';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ThankYou = ({onAddItem, items, totalItems}) => {
    

    return (
        <div>
            <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
            <Container className='mt-5'>
                <Card>
                    <Card.Body>
                    <Row>
                        <Col xs="12">
                        <h3 className='text-success'>
                            Thank you for your order!
                        </h3>
                        </Col>
                    </Row>
                    </Card.Body>
                </Card>
                
            </Container>
            
            <Footer />
        </div>
    );
};

export default ThankYou;