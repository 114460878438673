import Card from 'react-bootstrap/Card';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

import '../config';

const Discover = () => {
    
    const pStyle = {
        fontSize: '20px',
    };

    const imgStyle =  {
        width: '100%',
    }

    return (
        <Container>
        <Card className='mt-5 p-2'>
            <Card.Body>
                <Row>
                    <Col xs="12" md="6">
                        <Image style={imgStyle} src={global.config.site.discover_logo} />
                    </Col>
                    <Col xs="12" md="6">
                        <h1>{global.config.site.discover_title}</h1>
                        <p className='pt-3' style={pStyle}>{global.config.site.discover_text}</p>
                        <Link to="/shop">
                            <Button className="pr-5 pl-5" style={pStyle} variant="outline-primary">SEE THE COLLECTION</Button>{' '}
                        </Link>
                    </Col>
                    
                </Row>
            </Card.Body>
        </Card>
        </Container>
    );
};

export default Discover;