import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../config';
import { onAddItem } from '../App';
import Header from './header';
import { Row, Col, Image, Container, Button, Card, Toast, Form } from 'react-bootstrap';
import Footer from './footer';
import RelatedProducts from './RelatedProducts';

const Post = ({onAddItem, items, totalItems}) => {
    const [post, setPost] = useState({});
    const { id } = useParams();
    const [qty, setQty] = useState(1);

    const pStyle = {
        fontSize: '20px',
    };

    const numStyle = {
        width: '20%',
    };

    const toastStyle = {
        width: '100%',
    };


    useEffect(() => {
        const getPost = async () => {
            const resp = await fetch(`${global.config.api.product_url}?product_id=${id}&site=${global.config.api.site_id}`);
            const postResp = await resp.json();
            setPost(postResp);
        };

        getPost();
    }, [id]);

    const [showToast, setShowToast] = useState(false);

    const toggleShowToast = () => {
        
        setShowToast(!showToast);
        
    };

    const setAdditem = () => {
        setShowToast(!showToast);
        
        var price_point = post.price.replace('$','');
        var sub_total = parseFloat(qty) * parseFloat(price_point);
        
        onAddItem({
            id,
            qty: qty,
            desc: post.description,
            title: post.title,
            price: price_point,
            sub_total: sub_total,
            logo: post.logo
        })
    };

    if (!Object.keys(post).length) return <div />;

    return (
        <>
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
        <Container>
            <Row className='mt-2'>
            <Col xs="12" >
                    <Toast style={toastStyle} bg='danger' show={showToast} onClose={toggleShowToast} delay={9000} autohide>
                
                        <Toast.Body>
                            Item was successfully add to your cart!
                            <span className='pull-right'><Link to="/cart">View Cart</Link></span>
                        </Toast.Body>
                    </Toast>
                </Col>
            </Row>
            <Row className='mt-5'>
                
                <Col className='p-5' md="6" xs="12">
                <Image alt={post.title} width="100%" src={post.logo} />
                </Col>
                <Col className='p-5' md="6" xs="12">
                    <h3>{post.title}</h3>
                    <p>{post.price}</p>
                    <Form>
                        <Form.Group as={Row}>
                            <Col sm="3">
                                <Form.Control type='number' min='1' value={qty} onChange={event => setQty(event.target.value.replace(/\D/,''))}/>
                            </Col>
                            <Col sm="9">
                                <Button type="button" className="pr-5 pl-5 btn-sm" style={pStyle} variant="outline-primary" onClick={() => setAdditem()}>
                                    Add to cart
                                </Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
                
            </Row>
            
            <Row>
                <Col xs="12">
                    <Card className='mt-5 p-3'>
                        <Card.Body>
                            <h3>Product Details</h3>
                            <p>{post.description}</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                <Card className='mt-5'>
                        <Card.Body>
                    <RelatedProducts />
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    );
};

export default Post;