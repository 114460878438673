
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';

import CartMenu from './cart_menu';
import '../config';

const Header = ({onAddItem, items, totalItems}) => {
    
    function GetLogo(){
        if (global.config.site.nav_logo){
            
            return <Image className="pb-5" height="200" src={"/" + global.config.site.nav_logo} />;
        } else {
            if (global.config.site.nav_title){
                return <Navbar.Brand href="/"><h3>{global.config.site.nav_title}</h3></Navbar.Brand>;
            }
        }
    }

    const navLinkStyle = {
        fontSize: '24px',
    };

    return (
        
        <Container className="justify-content-center">
            <Stack className="justify-content-center pt-5" direction="horizontal" gap={3}>
                <GetLogo></GetLogo>
            </Stack>
            <Navbar className="justify-content-center" expand="md">
                
                
                <CartMenu className='d-block d-md-none pe-3' onAddItem={onAddItem} items={items} totalItems={totalItems}></CartMenu>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className='float-right'>
                    
                </Navbar.Toggle>
                
                <Navbar.Collapse className="justify-content-center" id="basic-navbar-nav">
                
                    <Nav className="justify-content-center">
                    
                        <Nav.Link className='me-2' style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                        <Nav.Link className='me-2' style={navLinkStyle} href="/cart">{'Cart'.toUpperCase()}</Nav.Link>
                        <Nav.Link className='me-2' style={navLinkStyle} href="/shop">{'Shop'.toUpperCase()}</Nav.Link>
                        <Nav.Link className='me-2' style={navLinkStyle} href="/#contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                        <CartMenu className='d-none d-md-block' onAddItem={onAddItem} items={items} totalItems={totalItems}></CartMenu>
                        
                    </Nav>
                
                </Navbar.Collapse>
                
            </Navbar>
        </Container>
        
        
    );
};

export default Header;