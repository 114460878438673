
import { Collection } from "usetheform";
import CartItem from "./CartItem";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Image, Container, Table, Card, Stack, Button } from 'react-bootstrap';
import Header from './header';
import '../config';
import Footer from "./footer";

const Cart = ({ items, onRemoveItem, onAddItem, onUpdateQtyItem, subTotal, totalItems }) => {

  const divStyle = {
      border: '1px solid rgba(0, 0, 0, 0.175)',
      borderRadius: '5px',
  };

  const pStyle = {
      fontSize: '20px',
      width: '100%',
  };

  return (
    <>
    
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
        <Container>
            
            <center><h1 className='mt-5'>CART</h1></center>

            <Row className='m-3'>

            <Card className='mt-2 p-2'>
              <Card.Body>
                <Table className="d-none d-md-block" borderless='true'>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Sub Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.length === 0 &&
                      <tr>
                        <td colSpan='6'><p>Your cart is currently empty.</p></td>
                      </tr>
                    }
                  {items &&
                          items.map((item) => (
                            <CartItem item={item} onRemoveItem={onRemoveItem} onUpdateQtyItem={onUpdateQtyItem} key={item.id} />
                          ))}
                  </tbody>
                </Table>
                <div className="d-block d-md-none">
                  <Row className="">
                      {items.length === 0 &&
                          <Col xs="12">
                            <p>Your cart is currently empty.</p>
                          </Col>
                        }

                        {items &&
                          items.map((item) => (
                            <CartItem col='true' item={item} onRemoveItem={onRemoveItem} onUpdateQtyItem={onUpdateQtyItem} key={item.id} />
                          ))}
                  </Row>
                </div>
              </Card.Body>
            </Card>
            </Row>

            <Row className='m-3'>

            <Card className='mt-3'>
              <Card.Body>
                      <h1>Cart Totals</h1>
                      <Row>
                        <Col>
                          <div style={divStyle}>
                            <Table borderless='true' >
                              <tbody>
                                <tr>
                                  <td>SubTotal</td>
                                  <td>${subTotal.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td>Total</td>
                                  <td>${subTotal.toFixed(2)}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                      </Col>
                      </Row> 
                      <Row className="mt-5">
                        <Col xs="12">
                        <Stack className="justify-content-center" direction="horizontal" gap={5}>
                          <Link to='/checkout' style={pStyle} >
                            <Button className="pr-5 pl-5" style={pStyle} variant="outline-primary">PROCEED TO CHECKOUT</Button>{' '}
                          </Link>
                        </Stack>
                        </Col>
                      </Row>
              </Card.Body>
              </Card>
            </Row>
            
        </Container>
        <Footer />
        </>
  );
};

export default Cart;
