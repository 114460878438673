import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import Posts from './components/shop';
import Post from './components/product';
import Home from './components/home';
import Form from "usetheform";
import Cart from './components/Cart';
import './App.css';
import './config';
import Checkout from './components/Checkout';
import ThankYou from './components/ThankYou';
import { toBePartiallyChecked } from '@testing-library/jest-dom/matchers';
import TermsConditions from './components/TermsConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import ShippingPolicy from './components/ShippingPolicy';

function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

function App() {

  const [items, setCartItem] = useState([]);
  const [subTotal, setSubTotal] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  const [totalItems, setTotalItems] = useState(0);
  

  const onRemoveItem = (idToRemove) => {
    const updatedCart = items.filter(item => item.id !== idToRemove);
    
    setCartItem(updatedCart);
    

    let stringlist = JSON.stringify(updatedCart)
    
    window.sessionStorage.setItem("items", stringlist);

    let sum = updatedCart.reduce(function(prev, current) {
      return prev + +current.sub_total
    }, 0);
    setSubTotal(sum);

    let sumItems = updatedCart.reduce(function(prev, current) {
      return prev + +current.qty
    }, 0);
    setTotalItems(sumItems);
    
  };

  const onAddItem = (item_sent) => {
    const item = item_sent;
    const findInCart = items.find(item => item.id === item_sent.id);
    
    const updatedWithoutCart = items.filter(item => item.id !== item_sent.id);
    if (findInCart){
      findInCart.qty = parseInt(findInCart.qty) + parseInt(item_sent.qty);
      findInCart.sub_total = parseFloat(findInCart.qty) * parseFloat(findInCart.price)
      updatedWithoutCart.push(findInCart);
      updatedWithoutCart.sort(dynamicSort('id'));
      
      setCartItem(updatedWithoutCart);
    } else {
      
      
      setCartItem((prev) => [...prev, item]);
      
    }

    
    
    // setCartItem((prev) => [...prev, item]);
  };

  const onUpdateQtyItem = (item_id, qty) => {
    
    const findInCart = items.find(item => item.id === item_id);
    
    const updatedWithoutCart = items.filter(item => item.id !== item_id);
    if (findInCart){
      findInCart.qty = parseInt(qty);
      findInCart.sub_total = parseFloat(qty) * parseFloat(findInCart.price);
      updatedWithoutCart.push(findInCart);

      updatedWithoutCart.sort(dynamicSort('id'));
      setCartItem(updatedWithoutCart);
    }
    
    let sum = items.reduce(function(prev, current) {
      return prev + +current.sub_total
    }, 0);
    setSubTotal(sum);

    let sumItems = items.reduce(function(prev, current) {
      return prev + +current.qty
    }, 0);
    setTotalItems(sumItems);
    // setCartItem((prev) => [...prev, item]);
  };


  const onChange= (state, isFormValid) => console.log('CHANGE', state, isFormValid);
  const onSubmit= (state) => console.log('SUBMIT', state);

  // if blank, we need to pull it from the session
  useEffect(() => {
    
    document.title = global.config.site.site_title; 
    document.querySelector("meta[name='description']").setAttribute('content', global.config.site.site_description); 

    var i;
    let retString = window.sessionStorage.getItem("items");
    if (retString){
      
      let retArray = JSON.parse(retString);
      if (retArray.length > 0 ){
        retArray.sort(dynamicSort('id'));
        for(i=0; i < retArray.length; i ++){
          onAddItem(retArray[i]);
        }
      }
      
    }
    
  }, []);

  //update session when item is added to the list
  useEffect(() => {
    
    let stringlist = JSON.stringify(items)
    
    if (items.length > 0 ){
      let sum = items.reduce(function(prev, current) {
        return prev + +current.sub_total
      }, 0);
      setSubTotal(sum);

      let sumItems = items.reduce(function(prev, current) {
        return prev + +current.qty
      }, 0);
      setTotalItems(sumItems);

      window.sessionStorage.setItem("items", stringlist);
    }
  }, [items]);

  

  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
      <Route path="/shop" element={<Posts onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
      <Route path="/thank-you" element={<ThankYou onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
      <Route path="/cart" element={<Cart onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} />} />
      <Route path="/checkout" element={<Checkout onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} setCartItem={setCartItem} />} />
      <Route path="/terms-and-conditions" element={<TermsConditions onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} setCartItem={setCartItem} />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} setCartItem={setCartItem} />} />
      <Route path="/shipping-policy" element={<ShippingPolicy onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} setCartItem={setCartItem} />} />
      <Route path="/posts/:id" element={<Post onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
    </Routes>
  </Router>
  );
}

export default App;