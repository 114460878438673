import React, { useEffect, useState } from 'react';

import { Link } from "react-router-dom";
import { Row, Col, Container } from 'react-bootstrap';

import Image from 'react-bootstrap/Image';

import '../config';

const BestSeller = () => {
    var index = 0;

    

    const colStyle = {
        border: '1px solid rgba(0, 0, 0, 0.175)',
    };

    function GetImage(props){
        
        return <Col className='p-3' xs="3" key={props.product.id}>
                  <div style={colStyle} className='p-3'>
            <Link to={`/posts/${props.product.id}`}>
                <Image alt={props.product.title} width="100%" src={props.product.logo} />
                
            </Link>
            </div>  
        </Col>
    }
        

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(`${global.config.api.products_url}?limit=4&site=${global.config.api.site_id}`);
            const postsResp = await resp.json();
            setPosts(postsResp);
        };

        getPosts();
    }, []);


    return (
        <Container className='mt-5'>
            <center><h1>BEST SELLERS</h1></center>
            <Row>
                
                {posts.map(post => (
                    
                    <GetImage product={post} key={post.id} />
                ))}

                
            </Row>
            
        </Container>
    );
};

export default BestSeller;