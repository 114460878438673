
import '../config';
import Header from './header';
import Discover from './discover';
import BestSeller from './best_sellers';
import OrderShipping from './order_shipping';
import OurProducts from './our_products';
import CompanyInfo from './company_info';
import Footer from './footer';

const Home = ({onAddItem, items, totalItems}) => {
    

    return (
        <div>
            <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
            <Discover />
            <BestSeller />
            <OrderShipping />
            <OurProducts />
            <CompanyInfo />
            <Footer />
        </div>
    );
};

export default Home;