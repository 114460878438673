import { Link} from "react-router-dom";
import { Row, Col, Container, Card} from 'react-bootstrap';
import Header from './header';
import '../config';
import Footer from "./footer";


const TermsConditions = ({ items, onRemoveItem, onAddItem, onUpdateQtyItem, subTotal, setCartItem, totalItems }) => {

  const divStyle = {
      border: '1px solid rgba(0, 0, 0, 0.175)',
      borderRadius: '5px',
  };

  const spanRequired = {
     color: 'red',
  }
  const pStyle = {
      fontSize: '20px',
      width: '100%',
  };

  return (
    <>
    
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
        <Container>
            
            <Row className='m-3'>
            
            <Card className='mt-2 p-2'>
              <Card.Body>
                <Row>
                    <Col xs="12">
                        <h1 className='mt-5'>Terms and Conditions</h1>
                    </Col>
                </Row>
                
                
                <Row className="mt-3 g-0">
                    <Col>
                    <p>
                    CAREFULLY READ AND UNDERSTAND THESE TERMS BEFORE ORDERING ANY PRODUCT THROUGH THIS WEBSITE.
                    </p>
                    
                    <p>
                    ATTENTION: This is a legal agreement (the “Agreement”) between You, the individual, company or organization (“you,” “your,” or “Customer”) and {global.config.terms.company} (“we,” “our”, “Company”). By ordering, accessing, using or purchasing (“Product”) through this website or related websites (collectively the “Website”), you are agreeing to be bound by, and are becoming a party to, this Agreement. We may at our sole and absolute discretion change, add, modify, or delete portions of this Agreement at any time without notice. It is your sole responsibility to review this Agreement for changes prior to use of the Website or purchase of the Product.
                    
                    </p>
                    <p>
                    IT IS STRONGLY RECOMMENDED THAT YOU REVIEW THIS DOCUMENT IN ITS ENTIRETY BEFORE ACCESSING, USING OR BUYING ANY PRODUCT THROUGH THE WEBSITE AND PRINT A COPY FOR YOUR RECORDS.
                    </p>
                    <h3>Terms & Conditions</h3>
                    <p>
                    Please carefully read the following terms and conditions as when you purchase any of the products from our website, you agree and are bound to the following terms and conditions. Your order will not be processed unless you check the box near the terms and conditions signifying that you have read and agreed to the terms.
                    </p>
                    <p>
                    This Agreement is between our Company and you (“you” or “Customer”). This Section sets forth the terms and conditions which apply to the use by you of the website (as defined below) and any other product or service offered for sale by us and/or our affiliates. 
                    </p>
                    <p>
                    The right to use any product or service offered by our Company is personal to you and is not transferable to any other person or entity. We reserve the right to make changes to the website, policies, and these terms at any time without notice.
                    </p>
                    <h3>Ordering & Shipping</h3>
                    <p>
                    By placing an order, you will be charged the full price of the package you selected and the order will be shipped to the address you provided during checkout. All items are processed and shipped within 3 business days of purchase and are shipped Priority mail via USPS. Please allow 3-5 business days to receive your product(s).
                    </p>
                    <h3>Indemnification</h3>
                    <p>
                    You agree to defend, indemnify and hold harmless the Company, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including attorneys’ fees, arising out of the use by you of the website, including claims by other users, access, products or memberships.
                    </p>
                    <h3>One Time Purchase Terms</h3>
                    <p>
                    When ordering your item, you will be charged depending on which item is selected at the time of the order. There will be no additional charges after the completion of this one time transaction. If this product is not right for you, or you have any questions, contact customer service with any questions by calling  {global.config.terms.phone} or e-mailing <Link to={`mailto:${global.config.terms.email}`}>{global.config.terms.email}</Link>. Charges will appear on your credit card statements as <Link to={`${global.config.terms.website}`}>{global.config.terms.website}</Link>.
                    </p>
                    <h3>Return Policy</h3>
                    <p>
                    We believe in complete customer satisfaction. You have 30 days to request a refund after you have receipt of order. We reserve the right to refuse a refund to any customer who repeatedly requests refunds or who, in our judgment, requests refunds in bad faith.
                    </p>
                    <p>
                    To return a product, you will need to obtain a Return Merchandise Authorization (RMA) number by contacting us by calling <Link to={`tel:${global.config.terms.phone}`}>{global.config.terms.phone}</Link> or e-mailing <Link to={`mailto:${global.config.terms.email}`}>{global.config.terms.email}</Link>. Our customer service department will email you an RMA number within 24-48 hours of your request. You will be refunded only for the opened or unopened products you send back to us. You will need to send the product back to the address provided below. Upon receipt you will receive a full refund of purchase price, minus the shipping, handling or other charges.  
                    </p>
                    <p>
                    Once our Fulfillment Center has received the package and relayed the correct information to us, you will be issued a refund. Your refund will be credited back to your bank account and may take up to 3-5 business days to show in your statement, depending on the speed of the processing bank.
                    </p>
                    <p>
                    Address the return package to: 
                    </p>
                    <p>
                    Fulfillment Center <br></br>
                    {global.config.terms.return_address}<br></br>
                    {global.config.terms.return_address2}
                    </p>
                    <h3>Return Process</h3>
                    <p>
                    If you are not satisfied and want to return the product, please write an email with your location to <Link to={`mailto:${global.config.terms.email}`}>{global.config.terms.email}</Link> and we will provide you with the address of the nearest return center.
                    </p>
                    <p>
                    Be sure to write your RMA # on the outside of the envelope for proper account credit.
                    </p>
                    <p>
                    We are not responsible for lost or stolen items. We recommend all returned items to be sent using some type of delivery confirmation system to ensure proper delivery.
                    </p>
                    <p>
                    After the shipping department receives your return, it generally takes 7 business days or sooner to process your refund. Once a return is processed, it usually takes 3-5 business days for the return to be posted to your account, depending on your financial institution.
                    </p>
                    <p>
                    Packages marked “Return to Sender” will NOT be processed or refunded. Returned packages will only be refunded with an RMA number that was provided by Customer Service. Call Customer Service at <Link to={`tel:${global.config.terms.phone}`}>{global.config.terms.phone}</Link> for your RMA number. RMA numbers remain valid for 30 days.
                    </p>
                    <p>
                    Refunds will only be issued to the same form of payment originally used for purchase.
                    </p>
                    <p>
                    Customer is responsible for return shipping charges.
                    </p>
                    <p>
                    After the warehouse receives your return, it generally takes 7 business days to process your return. Please keep in mind that your bank typically posts credit in the billing cycle in which it was received. Therefore, the number of days it takes for credit to post to your account may vary, depending on your banking institution’s billing and credit schedule.
                    </p>
                    <h3>Representations</h3>
                    <p>
                    You hereby represent and warrant that:<br></br>
                    You are age eighteen (18) or older.<br></br>
                    You have read this Agreement and thoroughly understand the terms contained.<br></br>
                    You further represent that our Company has the right to rely upon all information and may contact you by email, telephone or postal mail for any purpose regarding the use of this website
                    </p>
                    <h3>Billing & Customer Support</h3>
                    <p>
                    We welcome all support inquires by the following methods:
                    </p>
                    <p>
                    {global.config.terms.company}<br></br>
                    {global.config.terms.address1}<br></br>
                    Phone: <Link to={`tel:${global.config.terms.phone}`}>{global.config.terms.phone}</Link><br></br>
                    Email: <Link to={`mailto:${global.config.terms.email}`}>{global.config.terms.email}</Link><br></br>
                    Hours of operation: 24/7
                    </p>
                    </Col>
                </Row>
                
                <Row className="mt-3 g-0">
                
                </Row>
                
                

              </Card.Body>
            </Card>
            </Row>

            
            
        </Container>
        <Footer />
        </>
  );
};

export default TermsConditions;
