
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';

import '../config';

const Footer = () => {
    
    function GetLogo(){
        if (global.config.site.nav_logo){
            
            return <Image className="pb-5" height="150" src={"/" + global.config.site.nav_logo} />;
        } else {
            if (global.config.site.nav_title){
                return <Navbar.Brand href="/"><h3>{global.config.site.nav_title}</h3></Navbar.Brand>;
            }
        }
    }

    const navLinkStyle = {
        fontSize: '18px',
    };

    return (
        <Container className="justify-content-center mt-5 mb-5">
            <Stack className="justify-content-center pt-5" direction="horizontal" gap={3}>
                <GetLogo></GetLogo>
            </Stack>
            
            <div className='d-none d-md-block'>
            <Stack className="justify-content-center mb-2" direction="horizontal" gap={3}>

                <Nav.Link style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                <Nav.Link style={navLinkStyle} href="/shop">{'Shop Online'.toUpperCase()}</Nav.Link>
                <Nav.Link style={navLinkStyle} href="/terms-and-conditions">{'Terms And Conditions'.toUpperCase()}</Nav.Link>
                <Nav.Link style={navLinkStyle} href="/privacy-policy">{'Privacy Policy'.toUpperCase()}</Nav.Link>
                <Nav.Link style={navLinkStyle} href="/shipping-policy">{'Shipping Policy'.toUpperCase()}</Nav.Link>
                <Nav.Link style={navLinkStyle} href="/#contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                
            </Stack>
            </div>

            <div className='d-block d-md-none mx-auto justify-content-center text-center'>
                <Stack className="mb-2 mx-auto" gap={3}>

                    <Nav.Link style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                    <Nav.Link style={navLinkStyle} href="/shop">{'Shop Online'.toUpperCase()}</Nav.Link>
                    <Nav.Link style={navLinkStyle} href="/terms-and-conditions">{'Terms And Conditions'.toUpperCase()}</Nav.Link>
                    <Nav.Link style={navLinkStyle} href="/privacy-policy">{'Privacy Policy'.toUpperCase()}</Nav.Link>
                    <Nav.Link style={navLinkStyle} href="/shipping-policy">{'Shipping Policy'.toUpperCase()}</Nav.Link>
                    <Nav.Link style={navLinkStyle} href="/#contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                    
                </Stack>
            </div>
            
            <Stack className="justify-content-center" direction="horizontal" gap={5}>
                <p>Copyright © {global.config.site.nav_title}. All Rights Reserved</p>
            </Stack>
            <Stack className="justify-content-center" direction="horizontal" gap={5}>
                <Image height="30" src="/CreditCards.png"></Image>
            </Stack>
        </Container>
        
    );
};

export default Footer;