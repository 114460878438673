import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import '../config';

const CartMenu = ({onAddItem, items, className, totalItems}) => {
    
    const navLinkStyle = {
        fontSize: '24px',
    };

    return (
        <Nav.Link className={className} style={navLinkStyle} href="/cart"><FontAwesomeIcon icon={faShoppingCart} /> {totalItems} {'Items'.toUpperCase()}</Nav.Link>
        
    );
};

export default CartMenu;