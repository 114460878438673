import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Image, Container } from 'react-bootstrap';
import Header from './header';
import '../config';
import ShopItem from './ShopItem';
import Stack from 'react-bootstrap/Stack';
import ReactPaginate from 'react-paginate';
import Footer from './footer';
import LoadingOverlay from 'react-loading-overlay-ts';

const Posts = ({onAddItem, items, totalItems }) => {

    const [posts, setPosts] = useState([]);
    const [isActive, setActive] = useState(true)
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    let itemsPerPage = global.config.shop.items_per_page;
    let itemOffsetset = itemOffset + 1;
    
    const endOffset = itemOffset + itemsPerPage;

    let itemEndset = endOffset;
    if (endOffset > posts.length){
        itemEndset = posts.length;
    }
    
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = posts.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(posts.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % posts.length;
        console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };


    useEffect(() => {
        const getPosts = async () => {
            console.log(`${global.config.api.products_url}`);
            const resp = await fetch(`${global.config.api.products_url}?site=${global.config.api.site_id}`);
            const postsResp = await resp.json();
            setPosts(postsResp);
            setActive(false);
        };

        getPosts();
    }, []);

    return (
        <>
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
        <Container>
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading...'
        >
            <center><h1 className='mt-5'>SHOP</h1></center>
            <Row className='mt-5'>
                <Col xs="12">
                    <p>Showing {itemOffsetset} - {itemEndset} of {posts.length} results</p>
                </Col>
            <ShopItem onAddItem={onAddItem} items={items} currentItems={currentItems} />
            </Row>
            <Stack className="justify-content-center" direction="horizontal" gap={5}>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                className='react-paginate'
                nextClassName='react-paginate'
                previousClassName='react-paginate'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
            />
            </Stack>
            
        </LoadingOverlay>
        </Container>
        <Footer />
        </>
        
    );
};

export default Posts;