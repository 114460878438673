import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Image, Container } from 'react-bootstrap';
import Header from './header';
import '../config';

const ShopItem = ({onAddItem, items, currentItems}) => {

    const colStyle = {
        border: '1px solid rgba(0, 0, 0, 0.175)',
    };

    const titleStyle = {
        fontSize: '26px',
        color: '#000',
        textDecoration: 'none',
    };

    const priceStyle = {
        fontSize: '20px',
        textDecoration: 'none',
    };
    
    function GetImage(props){
        
        return <Col className='p-3' xs="12" md="4" key={props.product.id}>
                  <div style={colStyle} className='p-3'>
            <Link style={{textDecoration:'none'}} to={`/posts/${props.product.id}`}>
                <Image alt={props.product.title} width="100%" src={props.product.logo} />
                <p style={titleStyle}>{props.product.title}</p>
                <p style={priceStyle}>{props.product.price}</p>
            </Link>
            </div>  
        </Col>
    }

    
    return (
        
        <>
        {currentItems &&
              currentItems.map((item) => (
                <GetImage product={item} key={item.id} />
              ))}
        </>
    );
};

export default ShopItem;