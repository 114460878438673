import React, { useState } from 'react';
import { Link} from "react-router-dom";
import { Row, Col, Image, Container, Table, Card, Stack, Button, Form, Alert } from 'react-bootstrap';
import Header from './header';
import '../config';
import Footer from "./footer";
import CountrySelector from "./CountrySelector";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from 'react-number-format';

import isEmail from 'validator/lib/isEmail';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
  } from '../utils'

  


const Checkout = ({ items, onRemoveItem, onAddItem, onUpdateQtyItem, subTotal, setCartItem, totalItems }) => {

    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
      });
    
    const handleInputChange = (evt) => {
    

    if (evt.target.name === 'number') {
        evt.target.value = formatCreditCardNumber(evt.target.value)
        
      } else if (evt.target.name === 'expiry') {
        evt.target.value = formatExpirationDate(evt.target.value)
      } else if (evt.target.name === 'cvc') {
        evt.target.value = formatCVC(evt.target.value)
      }

    setState((prev) => ({ ...prev, [evt.target.name]: evt.target.value }));
    }

    const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
    }

    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("US");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [zip, setZip] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            setValidated(true);
            
            if (isEmail(email)){

            
                // post to api
                try {
                    let res = await fetch(global.config.api.send_order_url + "?site=" + global.config.api.site_id, {
                    method: "POST",
                    headers: new Headers({'content-type': 'application/json'}),
                    body: JSON.stringify({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phone: phone,
                        country: country,
                        address: address,
                        addressadd: address2,
                        city: city,
                        state: addressState,
                        zip: zip,
                        cc: state.number,
                        cvv: state.cvc,
                        expiry: state.expiry,
                    }),
                    });
                    
                    if (res.status === 200) {
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPhone("");
                    setAddress("");
                    setAddress2("");
                    setCity("");
                    setAddressState("");
                    setCountry("US");
                    
                    window.sessionStorage.removeItem("items");
                    setCartItem([]);
                    setMessage("Order was successfull");
                    navigate("/thank-you")
                    } else {
                    setMessage("An unknown error occured, please try again");
                        
                        event.stopPropagation();
                    }
                } catch (err) {
                    console.log(err);
                    setMessage("An unknown exception occured, please try again");
                    
                    event.stopPropagation();
                }
            } else {
                setMessage("Email Address is not valid!");
                    
                event.stopPropagation();
            }
        }
    };

  const divStyle = {
      border: '1px solid rgba(0, 0, 0, 0.175)',
      borderRadius: '5px',
  };

  const spanRequired = {
     color: 'red',
  }
  const pStyle = {
      fontSize: '20px',
      width: '100%',
  };

  return (
    <>
    
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
        <Container>
            
            <Row className='m-3'>
            <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit}>
            <Card className='mt-2 p-2'>
              <Card.Body>
                <Row>
                    <Col xs="12">
                        <h1 className='mt-5'>CHECKOUT</h1>
                        {message ? 
                        (
                            <Alert variant="danger">
                            
                            <p>
                              {message} 
                              
                            </p>
                            
                            <div className="d-flex justify-content-end">
                              
                            </div>
                          </Alert>
                        ) : null}
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm="12" md="6">
                        <h2>Billing Details</h2>

                        
                            <Row className="mb-3">
                                <Form.Group as={Col} sm="12" md="6" controlId="validationCustom01">
                                <Form.Label>First name<span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm="12" md="6" controlId="validationCustom02">
                                <Form.Label>Last name<span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>Company Name (optional)</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Company Name"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>Country / Region <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <CountrySelector setCountry={setCountry} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>Street Address <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="House  number and street name"
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="Apartment, suite, unit, etc. (optional)"
                                    value={address2}
                                    onChange={e => setAddress2(e.target.value)}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>Town / City <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="City"
                                    value={city}
                                    onChange={e => setCity(e.target.value)}
                                />
                                
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>State <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="State"
                                    value={addressState}
                                    onChange={e => setAddressState(e.target.value)}
                                />
                                
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>Postcode / ZIP <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Postcal code"
                                    value={zip}
                                    onChange={e => setZip(e.target.value)}
                                />
                                
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>Phone <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                
                                <PatternFormat placeholder="Phone Number" className="form-control" name="phone" onChange={e => setPhone(e.target.value)} format="+1 (###) ###-####" valueIsNumericString={true} />

                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>Email <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        
                    </Col>
                    <Col sm="12" md="6">
                        <h2>Additional Information</h2>
                        <Form.Group className="mt-3 mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Order notes (optional)</Form.Label>
                            <Form.Control as="textarea" placeholder="Notes about your order, e.g. special notes for delivery." rows={6} />
                        </Form.Group>
                    </Col>
                    <Col className="mt-5" sm="12">
                        <h2>Your Order</h2>
                        <Row>
                            <Col>
                                <div style={divStyle}>
                                    <Table borderless='true' >
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Subtotal</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                        {items.length === 0 &&
                                            <tr>
                                                <td colSpan='2'><p>Your cart is currently empty.</p></td>
                                            </tr>
                                            }
                                        {items &&
                                                items.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.title} x{item.qty}</td>
                                                        <td>${item.sub_total.toFixed(2)}</td>
                                                    </tr>
                                                    
                                                ))}

                                        <tr>
                                        <td>SubTotal</td>
                                        <td>${subTotal.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                        <td>Total</td>
                                        <td>${subTotal.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row> 
                    </Col>
                    <Col className="mt-5" xs="12">
                        <Image src="/mastercard.png" />
                        <Image src="/visa.png" />
                        <Image src="/discover.png" />
                        <Image src="/amex.png" />
                    </Col>
                </Row>
                <Row className="mt-3 g-0">
                    <Card>
                        <Card.Body>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                <Form.Label>Card Number <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                
                                <input
                                    type='tel'
                                    name='number'
                                    className='form-control'
                                    placeholder='Card Number'
                                    pattern='[\d| ]{16,22}'
                                    maxLength='19'
                                    required
                                    onChange={e => handleInputChange(e)}
                                    onFocus={e => handleInputFocus(e)}
                                />

                                
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" >
                                <Form.Label>Expiry (MM/YY) <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <input
                                    type='tel'
                                    name='expiry'
                                    className='form-control'
                                    placeholder='Valid Thru'
                                    pattern='\d\d/\d\d'
                                    required
                                    onChange={(e) => handleInputChange(e)}
                                    onFocus={(e) => handleInputFocus(e)}
                                />
                                
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" >
                                <Form.Label>Card Code <span style={spanRequired}>&nbsp;*</span></Form.Label>
                                <input
                                    type='tel'
                                    name='cvc'
                                    className='form-control'
                                    placeholder='CVC'
                                    pattern='\d{3,4}'
                                    required
                                    onChange={e => handleInputChange(e)}
                                    onFocus={e => handleInputFocus(e)}
                                />
                                
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                        </Card.Body>
                    </Card>
                </Row>
                
                <Row className="mt-3 g-0">
                    <Col>
                        <p>
                        Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our&nbsp;
                        <Link to="/privacy-policy">
                        privacy policy.
                        </Link>
                        </p>
                    </Col>
                </Row>
                
                <Row className="mt-3 g-0">
                <Form.Group className="mb-3">
                
                    <Form.Check
                        required
                        label={`I am at least 18 years of age and agree to the ${global.config.site.company.website}terms-and-conditions/. By clicking the Place Order button and submitting this order, I agree that my card will be subjected to the total listed above. If this product is not right for me, or I have any questions, contact customer service with any questions by calling ${global.config.site.company.customer_support_number} or e-mailing ${global.config.site.company.email}. All items are processed and shipped within 3 business days of purchase and are shipped Priority mail via USPS. Please allow 3-5 business days to receive your product(s). Charges will appear on my credit card statements as ${global.config.site.company.website}. Thank you for your business. *`}
                        feedback="You must agree before submitting."
                        feedbackType="invalid"
                        
                    />
                    
                </Form.Group>
                </Row>
                
                <Row className="mt-5">
                    <Col xs="12">
                    <Stack className="justify-content-center" direction="horizontal" gap={5}>
                        
                        <Button type="submit" className="pr-5 pl-5" style={pStyle} variant="outline-primary">Place Order</Button>{' '}
                        
                    </Stack>
                    </Col>
                </Row>

              </Card.Body>
            </Card>
            </Form>
            </Row>

            
            
        </Container>
        <Footer />
        </>
  );
};

export default Checkout;
