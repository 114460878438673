import { Row, Col, Container, Card } from 'react-bootstrap';
import '../config';

const OrderShipping = () => {
   
    const pStyle = {
        fontSize: '16px',
    };

    return (
        <Container>
        <Card className='mt-5 p-5'>
            <Card.Body>
                <Row>
                    <Col xs="12" sm="12">
                        <center>
                            <h1>Ordering & Shipping</h1>
                        </center>
                    </Col>
                    <Col xs="12">
                        
                        <p className='pt-3' style={pStyle}>
                        By placing an order, you will be charged the full price of the package you selected, and the order will be shipped to the address you provided during checkout. All items are processed and shipped within 3 business days of purchaes and are shipped Priority mail via USPS. Please allow 3-5 business days to receive your product(s).
                        </p>
                    </Col>
                    
                </Row>
            </Card.Body>
        </Card>
        </Container>
    );
};

export default OrderShipping;