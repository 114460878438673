module.exports = global.config = {
    api: {
        products_url: "https://blogger.ngrok.io/api/products/",
        product_url: "https://blogger.ngrok.io/api/product/",
        send_order_url: "https://blogger.ngrok.io/api/send/order/",
        site_id: 1,
    },
    shop: {
        items_per_page: 6,
    },
    terms:{
        company: 'DFM Bold Collective',
        email: 'support@americancharmshop.com',
        phone: '1-833-391-1675',
        address1: '221 N Hogan ST #350 Jacksonville, FL 32202',
        address2: '',
        website: 'https://americancharmshop.com/',
        return_address: 'PO BOX 447',
        return_address2: 'Lakeland, FL. 33815, USA',
    },
    site: {
        // include name to show name, include logo to show logo...
        site_title: 'American Charm Shop',
        site_meta: '',
        site_description: 'American Charm Shop Mega Sale',
        nav_title: 'American Charm Shop',
        nav_logo: 'loggos.png',
        discover_logo: 'discover_logo.jpg',
        discover_title: 'Discover Our Jewelry Collection',
        discover_text: 'EVERY PIECE IN OUR COLLECTION IS METICULOUSLY CRAFTED WITH PRECISION AND PASSION, ENSURING THAT YOU RECEIVE THE HIGHEST QUALITY ADORNMENTS THAT WILL STAND THE TEST OF TIME.',
        company: {
            name: 'DFM Bold Collective LLC',
            address: '221 N Hogan ST #350 Jacksonville, FL 32202',
            return_address: 'Fulfillment Center, POBOX 447 Lakeland, FL 33815',
            customer_support_number: '1-833-391-1675',
            email: 'support@americancharmshop.com',
            website: 'https://americancharmshop.com/'
        }
    }
};